// https://fancyapps.com/docs/ui/fancybox
// https://www.npmjs.com/package/axios
// https://greensock.com/
// https://swiperjs.com/get-started

declare var Swiper : any;
declare var $ : any;
declare var AOS : any;
declare var gsap : any;
declare var anime : any;
declare var Panzoom : any;

// AOS.init({
//   once: true
// });

// import axios from "axios";

//////////////////////////////////////////////////////////
// Common function
import {swiper} from "./components/swiper";
import {navBar} from "./components/nav-bar";
import {pz} from "./components/panzoom";
import {optionForm} from "./components/_option-form";
import {popup} from "./components/_popup";
import {settings} from "./components/settings";

// Layout
import {header} from "./layout/header";
import {banner} from "./layout/banner";
import {footer} from "./layout/footer";

// Page
// import {index} from "./pages/index";
import {news} from "./pages/news";
import {searchInfo} from "./pages/search-info";
import {achievement} from "./pages/achievement";
import {dictionary} from "./pages/dictionary";
import {newsDetail} from "./pages/news-detail";
import {container} from "./pages/container";
import {index} from "./pages/index";
import {search} from "./pages/search";

// event
import {gsapScroll} from "./components/gsapScroll";

///////////////////////////////////////////////////////////////
// function Common
function common(){
  swiper();
  navBar();
  pz();
  optionForm();
  popup();
}

function layout(){
  header();
  banner();
  footer();
}

// function Page
function page(){
  // ----- add page in here ----- //
  news();
  searchInfo();
  achievement();
  dictionary();
  newsDetail();
  container();
  index();
  search();
}

function after(){
  settings();
  gsapScroll();
}

/////////////////////WEB NORMAL///////////////////////////
window.addEventListener('load', function() {
  common();
  layout();
  page();

  after();
})