declare var Swiper : any;

function initSwiper(strWrapper:string){
    // Kiểm tra tồn tại swiper custom
    let achiWrapper = document.querySelector(strWrapper);
    if (!achiWrapper){
        return;
    }

    // khởi tạo swiper cho swiper custom đó
    var swiper = new Swiper(`${strWrapper} .swiper`, {
        slidesPerView: 2,
        spaceBetween: 36,
        
        navigation: {
            nextEl: `${strWrapper} .swiper-button-custom-next`,
            prevEl: `${strWrapper} .swiper-button-custom-prev`,
        },

        breakpoints: {
            0:{
                slidesPerView: 1,
                spaceBetween: 10,
            },
            992:{
                slidesPerView: 2,
                spaceBetween: 12,
            },
            1220:{
                slidesPerView: 1,
                spaceBetween: 12,
            },
            1680:{
                slidesPerView: 2,
                spaceBetween: 12,
            }
        }
    });

    // // button vô giữa hình ảnh của swiper
    // let next = <HTMLElement> achiWrapper.querySelector('.swiper-button-custom-next');
    // let prev = <HTMLElement> achiWrapper.querySelector('.swiper-button-custom-prev');
    // let img = achiWrapper.querySelector('.swiper-slide img');
    
    // next.style.top = img.clientHeight / 2 + "px";
    // prev.style.top = img.clientHeight / 2 + "px";
    // window.addEventListener('resize', function(){
    //     next.style.top = img.clientHeight / 2 + "px";
    //     prev.style.top = img.clientHeight / 2 + "px";
    // })
}

export const newsDetail = function(){
    if (document.querySelector('.news-detail-1-swiper')){
        console.log('swiper')
        initSwiper('.news-detail-1-swiper');
    }
}