declare var Swiper : any;
declare var $ : any;

import {swiper} from "./swiper";

function initSwiper(strWrapper:string, strWrapperSub:string){
    // Kiểm tra tồn tại swiper custom
    let wrapper = document.querySelector(strWrapper);
    let wrapperSub = document.querySelector(strWrapperSub);
    if (!wrapperSub || !wrapper){
        return;
    }

    // khởi tạo swiper cho swiper custom đó
    var swiper = new Swiper(`${strWrapper} .swiper`, {
        slidesPerView: 4,
        spaceBetween: 36,
        freeMode: true,
        watchSlidesProgress: true,
        
        navigation: {
            nextEl: `${strWrapper} .swiper-button-custom-next`,
            prevEl: `${strWrapper} .swiper-button-custom-prev`,
        },

        breakpoints: {
            0:{
                slidesPerView: 3,
                spaceBetween: 10,
            },
            992:{
                slidesPerView: 3,
                spaceBetween: 12,
            },
            1220:{
                slidesPerView: 3,
                spaceBetween: 36,
            },
            1400:{
                slidesPerView: 4,
                spaceBetween: 36,
            }
        }
    });

    var swiper2 = new Swiper(`${strWrapperSub} .swiper`, {
        spaceBetween: 10,

        thumbs: {
          swiper: swiper,
        },
    });

    // // button vô giữa hình ảnh của swiper
    // let next = <HTMLElement> achiWrapper.querySelector('.swiper-button-custom-next');
    // let prev = <HTMLElement> achiWrapper.querySelector('.swiper-button-custom-prev');
    // let img = achiWrapper.querySelector('.swiper-slide img');
    
    // next.style.top = img.clientHeight / 2 + "px";
    // prev.style.top = img.clientHeight / 2 + "px";
    // window.addEventListener('resize', function(){
    //     next.style.top = img.clientHeight / 2 + "px";
    //     prev.style.top = img.clientHeight / 2 + "px";
    // })
}

function closeHandle(){
    let popup = document.querySelector('.section-popup');
    if (popup){
        let close = <HTMLElement>popup.querySelector('.popup-close');
        if (!close){
            return;
        }
        close.onclick = function(e){
            e.preventDefault();

            // remove popup
            popup.classList.remove('show');
        
            // scroll body default
            let body = document.querySelector('body');
            body.style.overflow = "";
        }
    }
}



function openImageHandle(){
    let popup = document.querySelector('.section-popup');
    let sectionImg = document.querySelector('.image');
    if (!popup || !sectionImg){
        return;
    }

    let boxItems = sectionImg.querySelectorAll('.box-2');
    boxItems.forEach(function(item:HTMLElement){
        item.onclick = function(e){
            e.preventDefault();

            let albums = item.getAttribute('data-album');

            let data = {
                albums
            }
            
            // remove scroll body
            let body = document.querySelector('body');
            body.style.overflow = "hidden";

            // add class
            popup.classList.add('show');
        
            // call API
            $.ajax({
                url: '/popup-images',
                type: 'POST',
                dataType: 'html',
                data: data
            }).done(function (result: any) {
                let wrapper = document.querySelector('.section-popup .popup-album-wrapper');
                wrapper.innerHTML = result;

                closeHandle();
                
                // init Swiper
                initSwiper(
                    '.section-popup .popup-album-thumbs .swiper-custom', 
                    '.section-popup .popup-album-swiper');

            }).fail(function () {
                console.log('API popup images failed!!!');
            })
        }
    })
}

function openVideoHandle(){
    let popup = document.querySelector('.section-popup');
    let sectionImg = document.querySelector('.video');
    if (!popup || !sectionImg){
        return;
    }

    let boxItems = sectionImg.querySelectorAll('.box-2');
    boxItems.forEach(function(item:HTMLElement){
        item.onclick = function(e){
            e.preventDefault();
            let numVideo = item.getAttribute('data-video')
            let data = {
                video: numVideo
            }
            
            // remove scroll body
            let body = document.querySelector('body');
            body.style.overflow = "hidden";

            // add class
            popup.classList.add('show');
        
            // call API
            $.ajax({
                url: '/popup-videos',
                type: 'POST',
                dataType: 'html',
                data: data
            }).done(function (result: any) {
                let wrapper = document.querySelector('.section-popup .popup-video-wrapper');
                wrapper.innerHTML = result;
                
                closeHandle();
            }).fail(function () {
                console.log('API popup videos failed!!!');
            })
        }
    })
}

function moreImagesHandle(){
    let more = <HTMLElement>document.querySelector('.image-more button');
    if (more){
        more.onclick = function(){
            let page = more.getAttribute('data-page');
            let value = more.getAttribute('data-value');
            let data = {
                page, value
            }

            // call API
            $.ajax({
                url: '/more-images',
                type: 'POST',
                dataType: 'html',
                data,
                beforeSend: function () {
                    more.classList.add('calling');
                }
            }).done(function (result: any) {
                more.classList.remove('calling');
                
                let wrapper = document.querySelector('.image-wrapper');
                let moreWrapper = wrapper.querySelector('.image-more');
                if (moreWrapper){
                    moreWrapper.remove();
                }
                wrapper.innerHTML += result;

                swiper();
                openImageHandle();
                moreImagesHandle();

            }).fail(function () {
                console.log('API more images failed!!!');
            })
        }
    }
}
function moreVideosHandle(){
    let more = <HTMLElement>document.querySelector('.video-more button');
    if (more){
        more.onclick = function(){
            let page = more.getAttribute('data-page');
            let value = more.getAttribute('data-value');
            let data = {
                page, value
            }

            // call API
            $.ajax({
                url: '/more-videos',
                type: 'POST',
                dataType: 'html',
                data,
                beforeSend: function () {
                    more.classList.add('calling');
                }
            }).done(function (result: any) {
                more.classList.remove('calling');
                
                let wrapper = document.querySelector('.video-wrapper');
                let moreWrapper = wrapper.querySelector('.video-more');
                if (moreWrapper){
                    moreWrapper.remove();
                }
                wrapper.innerHTML += result;

                openVideoHandle();
                moreVideosHandle();
                
            }).fail(function () {
                console.log('API more videos failed!!!');
            })
        }
    }
}

export const popup = function(){
    if (document.querySelector('.section-popup .popup-album-thumbs .swiper-custom')){
        initSwiper(
            '.section-popup .popup-album-thumbs .swiper-custom', 
            '.section-popup .popup-album-swiper');
    }

    openImageHandle();
    openVideoHandle();

    closeHandle();

    moreImagesHandle();
    moreVideosHandle();
}