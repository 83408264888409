
function clickOptionHandle(){
    if (!document.querySelector('.option-form')){
        return;
    }

    let optForm = document.querySelectorAll('.option-form');
    optForm.forEach(function(opt){
        // Khi click vào option select
        let selectOpt = <HTMLElement> opt.querySelector('.option-active');
        selectOpt.onclick = function(e){
            e.preventDefault();
            if (selectOpt.classList.contains('clicked')){
                selectOpt.classList.remove('clicked');
                return;
            }

            optForm.forEach(function(delClick){
                let selectOptDel = <HTMLElement> delClick.querySelector('.option-active');
                selectOptDel.classList.remove('clicked');
            })

            selectOpt.classList.add('clicked');
        }

        // Khi chọn các option
        let drops = opt.querySelectorAll('.option-drops__item');
        let activeOpt = opt.querySelector('.option-active');
        let spanOpt = opt.querySelector('.option-active .span');
        if (spanOpt && activeOpt){
            drops.forEach(function(item:HTMLElement){
                item.onclick = function(){
                    console.log(item.innerHTML);
                    spanOpt.innerHTML = item.innerHTML;
                    activeOpt.classList.remove('clicked');
                }
            })
        }
    })

    // Khi click ra ngoài option thì hủy clicked
    document.addEventListener('click', function(evt){
        var links = document.querySelectorAll('.option-form-select');
      
        links.forEach(function (item) {
          var evtTarget = <HTMLElement>evt.target;

          var listOptions = item.getElementsByClassName('option-active');
          var content = item.getElementsByClassName('option-drops');
      
          do {
            if (evtTarget === listOptions[0]) {
              return;
            }
            else if (evtTarget === content[0]) {
              return;
            }
      
            evtTarget = <HTMLElement>evtTarget.parentNode;
          } while (evtTarget);
      
          listOptions[0].classList.remove('clicked');
        })
    })
}

export const optionForm = function(){
    clickOptionHandle();
}