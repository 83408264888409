declare var Swiper : any;

function swiperBannerInit(){
    if (!document.querySelector('.banner .mySwiper')){
        return;
    }
    var swiper = new Swiper(".banner .mySwiper", {
        pagination: {
            spaceBetween: 30,
            el: ".banner .mySwiper .swiper-pagination",
            dynamicBullets: true,
            clickable: true,
            autoplay: {
                delay: 2500,
            },
            loop: true,
        },
    });
    // swiper.autoplay.start()
    swiper.autoplay.running = true;
}

export const banner = function (){
    swiperBannerInit();
}