declare var gsap: any;
declare var ScrollTrigger: any;

gsap.registerPlugin(ScrollTrigger);

function animateFrom(elem:any, flag:any,direction:any = null) {
    if (flag.i == 1){
        return;
    }
    flag.i = 1;

    direction = direction || 1;
    var x = 0,
        y = direction * 100;
    if (elem.classList.contains("gs_reveal_fromLeft")) {
        x = -500;
        y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
        x = 500;
        y = 0;
    }
    else if (elem.classList.contains("gs_reveal_fromBottom")) {
        x = 0;
        y = 200;
    }
    else if (elem.classList.contains("gs_reveal_fromTop")) {
        x = 0;
        y = -200;
    }
    else if (elem.classList.contains("gs_reveal_fromCenter")) {
        x = 0;
        y = 0;
    }
    else if (elem.classList.contains("gs_reveal_bounceInUp")) {
        x = 0;
        y = 2000;
    }
    if (elem.classList.contains("gs_reveal_bounceInUp")){
        gsap.fromTo(elem, { autoAlpha: 0 }, {
            duration: 1,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto",
        });
        elem.style.animation = "bounceInUp 1s ease forwards";
    }
    else{
        elem.style.transform = "translate(" + x + "px, " + y + "px)";
        elem.style.opacity = "0";

        gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto",
        });
    }
}

function hide(elem) {
    gsap.set(elem, { autoAlpha: 0 });
}

function initAnimation() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
        let flag = {i: 0}; // khởi tạo biến cờ, khi i = 1 thì sẽ không khởi chạy animation

        hide(elem); // assure that the element is hidden when scrolled into view
        ScrollTrigger.create({
            trigger: elem,
            // toggleActions: "play none none none",
            onEnter: function () { 
                animateFrom(elem, flag);
            },
            // onEnterBack: function () { animateFrom(elem, -1) },
            // onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
        });
    });

}

export const gsapScroll = function () {
    initAnimation();
}