function initButtonNav(){
    let sw = document.querySelectorAll('.swiper-custom');
    sw.forEach(function(item){
        let next = item.querySelector('.swiper-button-custom-next');
        let prev = item.querySelector('.swiper-button-custom-prev');
        
        next.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="13.433" height="31.92" viewBox="0 0 13.433 31.92">
            <g id="Group_2531" data-name="Group 2531" transform="translate(13.433) rotate(90)">
                <path id="Path_17562" data-name="Path 17562" d="M8.578,13.433H0L13.433,0h5.239Z" fill="#fff"/>
                <path id="Path_17563" data-name="Path 17563" d="M10.093,13.433h8.579L5.238,0H0Z" transform="translate(13.248)" fill="#fff"/>
            </g>
        </svg>
        `;
        prev.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="13.433" height="31.92" viewBox="0 0 13.433 31.92">
            <g id="Group_2531" data-name="Group 2531" transform="translate(13.433) rotate(90)">
                <path id="Path_17562" data-name="Path 17562" d="M8.578,0H0L13.433,13.433h5.239Z" fill="#fff"/>
                <path id="Path_17563" data-name="Path 17563" d="M10.093,0h8.579L5.238,13.433H0Z" transform="translate(13.248)" fill="#fff"/>
            </g>
        </svg>
        `;
    })
}


export const swiper = function (){
    initButtonNav();
}