import pagination from "../components/pagination";

function initPagination(){
    pagination.pagination({
        queryClassPagination: ".container-1 .box-pagination",
        listBox: ".container-1 .container-1-table__item",
        itemPages: 1,
        scroll: {
            id: "scrollContainer"
        }
    })
}

export const container = function(){
    initPagination();
}