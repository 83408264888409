declare var Panzoom:any;

function initPanzoom(){
    let fullcontent = document.querySelectorAll('.content-line');
    fullcontent.forEach(function(item){
        let allImg = item.querySelectorAll('img.panzoom');
        allImg.forEach(function(imgItem){
            let flag = false;
            if (window.innerWidth <= 640){
                if (flag== false){
                    const pzImg = Panzoom(imgItem, {
                        minScale: 1,
                        maxScale: 5,
                        
                    });
                    pzImg.zoom(1, { animate: true });
                    imgItem.addEventListener("wheel", pzImg.zoomWithWheel);
                    
                    flag = true;
                }
            }
            
        })

    })
}

export const pz = function(){
    initPanzoom();
}