
function changeWords (){
    const Dictionary = document.querySelector(".block-dictionary")
    if(Dictionary){
        const alphaItems = Dictionary.querySelectorAll(".dictionary-alpha_items")
        alphaItems.forEach(element => element.addEventListener('click',()=>{
            alphaItems.forEach(function(element){
                const alphaActive = element.classList.contains("active")
                if(alphaActive === true){
                    element.classList.remove("active")
                }
            })
            const data_alpha = element.getAttribute("data-words")
            element.classList.add("active")
            const wordsItems =  Dictionary.querySelectorAll(".dictionary-words")
            wordsItems.forEach(function(element){
                const data_words = element.getAttribute("data-words")
                const wordsActive = element.classList.contains("active")
                if(wordsActive === true){
                    element.classList.remove("active")
                }
                if(data_words === data_alpha){
                    element.classList.add("active")
                }
                
            })
        }))
    }
}

export const dictionary = function(){
//   gettotalLength();
    changeWords();
}