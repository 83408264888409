function DropdownNavBar(){
    if(document.getElementById('nav-bar')){
        var btnDrodowns = document.querySelectorAll('.nav-bar .nav-bar__content .flex-list');
        // var btnDrodown = <HTMLElement>document.querySelector('.nav-bar .nav-bar__content .flex-list .btnDropdown');
        btnDrodowns.forEach(function(btnDrodown:HTMLElement){
            btnDrodown.onclick = function(e){
                e.preventDefault();
                btnDrodown.classList.toggle('show');
            }    
        })

        // var navBarContent = document.querySelector('.nav-bar .nav-bar__content .nav-bar__content--item .dropdownList')
    }

}
export const navBar = function () {
    DropdownNavBar()
}