
function chooseHandle(){
    let si = document.querySelector('.si-1');
    if (!si){
        return;
    }
    let btns = si.querySelectorAll('.button-choose');
    let sections = si.querySelectorAll('.si-1__section');

    btns.forEach(function(btn:HTMLElement){
        btn.onclick = function(){
            btns.forEach(function(temp){
                temp.classList.remove('active');
            })
            btn.classList.add('active');

            let dtChoose = btn.getAttribute('data-choose');

            sections.forEach(function(sec){
                let dtSection = sec.getAttribute('data-section');
                if (dtChoose === dtSection){
                    sections.forEach(function(temp){
                        temp.classList.remove('active');
                    })
                    sec.classList.add('active');
                    return;
                }
            })
        }
    })
}

export const searchInfo = function(){
    chooseHandle();
}