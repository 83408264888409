function activeHamburgerHandle(){
    let hamburger = <HTMLElement>document.querySelector('.header-hamburger');
    if (hamburger){
        hamburger.onclick = function(){
            hamburger.classList.toggle('active');
        }
    }
}

function scrollHeader(){
    let header = document.getElementById('header');
    if (!header){
        return;
    }

    let hHeader = header.clientHeight;
    let timeoutScroll;

    // let banner = <HTMLElement>document.querySelector('.banner');
    // lấy section sau của header
    let nextHeader = <HTMLElement>header.nextElementSibling;

    // tạo sự kiện scroll
    // khi scrollY lớn hơn header clientHeight thì cho header có position fixed
    window.addEventListener('scroll', function(e){
        this.clearTimeout(timeoutScroll);
        
        timeoutScroll = this.setTimeout(function(){
            if (window.scrollY > hHeader){
                header.classList.add('scroll');
                if (nextHeader){
                    nextHeader.style.marginTop = hHeader + "px";
                }
            }
            else{
                header.classList.remove('scroll');
                if (nextHeader){
                    nextHeader.style.marginTop = "";
                }
            }
        }, 200)
    })
}

function menuNavMobileHandle(){
    let navMobile = document.querySelector('.header-nav-mobile');
    if (!navMobile){
        return;
    }

    let menuItems = navMobile.querySelectorAll('.menu li');
    menuItems.forEach(function(item){
        // let links = item.querySelector('li');
        // console.log([item]);
        // console.log(first);

        let first = <HTMLElement>item.children[0];
        // let second = <HTMLElement>item.children[1];
        // console.log(item);

        if (first){
            first.addEventListener('click', function(e){
                // e.preventDefault();
                // if (first.getAttribute('href') !== "javascript:void(0)"){

                // }
                if (item.classList.contains('clicked')){
                    item.classList.remove('clicked');
                    return;
                }

                if (item.classList.contains('menu-item')){
                    menuItems.forEach(function(del){
                        if (del.classList.contains('menu-item')){
                            del.classList.remove('clicked');
                        }
                    })
                }
                item.classList.add('clicked');
            })
        }
    })
}

function menuSubHeader(){
    let menuItems = document.querySelectorAll('.header .menu-item');
    menuItems.forEach(function(item){
        let check = false;
        let timeoutMouse:any;

        item.addEventListener('mouseover', function(){
            clearTimeout(timeoutMouse);
            timeoutMouse = setTimeout(function(){
                if (check){
                    return;
                }
                check = true;

                let menuSub = <HTMLElement>item.querySelector('.menu-item__sub ul');
                let header = document.querySelector('.header');
                let heightMenuSub = menuSub.clientHeight;
                let heightHeader = header.clientHeight;
                let heightWindow = window.innerHeight;

                if ((heightHeader + heightMenuSub) > (heightWindow - 80)){
                    let maxHeightMenuSub = heightWindow - 80 - heightHeader;
                    menuSub.style.maxHeight = maxHeightMenuSub + 'px';
                    menuSub.style.overflowY = "auto";
                }
            }, 300)
        })
        item.addEventListener('mouseout', function(){
            clearTimeout(timeoutMouse);
            timeoutMouse = setTimeout(function(){
                check = false;
            }, 300)
        })

    })

    
}

export const header = function(){
    activeHamburgerHandle();
    scrollHeader();
    menuNavMobileHandle();

    menuSubHeader();
}