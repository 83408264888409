declare var $: any;


function moreSearchHandle() {
    let more = <HTMLElement>document.querySelector('.search-more button');
    if (more) {
        more.onclick = function () {
            let page = more.getAttribute('data-page');
            let value = more.getAttribute('data-value');
            let data = {
                page, value
            }

            // call API
            $.ajax({
                url: '/more-search',
                type: 'POST',
                dataType: 'html',
                data,
                beforeSend: function () {
                    more.classList.add('calling');
                }
            }).done(function (result: any) {
                more.classList.remove('calling');

                let wrapper = document.querySelector('.search-wrapper .content');
                let moreWrapper = wrapper.querySelector('.search-more');

                if (moreWrapper) {
                    moreWrapper.remove();
                }
                wrapper.innerHTML += result;

                moreSearchHandle();

            }).fail(function () {
                console.log('API more search failed!!!');
            })
        }
    }
}

function apiHandle() {
    let btn = <HTMLElement> document.querySelector('.header-nav .search button');
    let btnInput = <HTMLElement> document.querySelector('.header-nav .search input');
    let btnMobile = <HTMLElement>document.querySelector('.header .nav__item .search button');
    let btnInputMobile = <HTMLElement>document.querySelector('.header .nav__item .search input');

    if (btn) btn.onclick = buttonCb;
    if (btnMobile) btnMobile.onclick = buttonCb;

    if (btnInput) btnInput.onkeydown = enterPressHandle;
    if (btnInputMobile) btnInputMobile.onkeydown = enterPressHandle;
}

function enterPressHandle(event){
    if(event.key === 'Enter') {
        let btn = <HTMLInputElement>this.parentElement.querySelector('button');

        btn.click();
    }
}

function buttonCb(e){
    let inp = <HTMLInputElement>this.parentElement.querySelector('input[type="text"]');
    let search = inp.value;
    // let url = window.location.origin;

    let valueText = "/search?text=" + search;
    // redirect search.html
    window.location.replace(valueText);
    
    // let data = {
    //     search
    // }
    // // call API
    // $.ajax({
    //     url: '/search-header',
    //     type: 'GET',
    //     dataType: 'html',
    //     data,
    // }).done(function (result: any) {
        
    // }).fail(function () {
    //     console.log('API more videos failed!!!');
    // })
}

export const search = function () {
    moreSearchHandle();
    apiHandle();
}