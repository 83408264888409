declare var Swiper : any;

function swiperIndexService (){
    var swiper = new Swiper(".mySwiperService", {
        slidesPerView: 2,
        spaceBetween: 0,
        breakpoints: {
            // when window width is >= 320px
            460: {
              slidesPerView: 2,
            },
            // when window width is >= 480px
            768: {
              slidesPerView: 3,
            },
            // when window width is >= 640px
            1201: {
              slidesPerView: 4,
            }
        },
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
        speed:1500
    });
}

function swiperIndex(){
    var swiper = new Swiper(".mySwiperIndex", {
        slidesPerView: 3,
        spaceBetween: 38,
        // autoplay: {
        //     delay: 3000,
        // },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            "@0.55": {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            "@1.00": {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            "@1.35": {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            "@1.76": {
                slidesPerView: 3,
                spaceBetween: 38,
            },
          },
    });
}
function swiperIndexPartner1(){
    var swiper = new Swiper(".mySwiperPartner1", {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
            delay: 2500,
        },
        breakpoints: {
            // "@0.00": {
            //     spaceBetween: 10,
            // },
            // "@1.00": {
            //     spaceBetween: 20,
            // },
            // "@1.35": {
            //     slidesPerView: 3,
            //     spaceBetween: 38,
            // },
            0:{
                spaceBetween: 10,
            },
            640:{
                spaceBetween: 10,
            },
            1280:{
                spaceBetween: 20,
            },
            1440:{
                spaceBetween: 38,
            }
          },
    });
}
function swiperIndexPartner2(){
    var swiper = new Swiper(".mySwiperPartner2", {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
            delay: 2500,
        },
        breakpoints: {
            // "@0.00": {
            //     spaceBetween: 10,
            // },
            // "@1.00": {
            //     spaceBetween: 20,
            // },
            // "@1.35": {
            //     slidesPerView: 3,
            //     spaceBetween: 38,
            // },
            0:{
                spaceBetween: 10,
            },
            640:{
                spaceBetween: 10,
            },
            1280:{
                spaceBetween: 20,
            },
            1440:{
                spaceBetween: 38,
            }
        },
    });
}

// function sendAdvice(){
    
// }

export const index = function (){
    swiperIndexService();
    swiperIndex();
    swiperIndexPartner1();
    swiperIndexPartner2();
}