import pagination from "../components/pagination";

function initPagination(){
    pagination.pagination({
        queryClassPagination: ".news-1 .box-pagination",
        listBox: ".news-1 .news-item",
        itemPages: 5,
        scroll: {
            id: "scrollNews"
        }
    })
}

export const news = function(){
    initPagination();
}