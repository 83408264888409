declare var Swiper:any;

function swiperFooterMember(){
    if (!document.querySelector('.footer-member .swiper')){
        return;
    }
    var swiper = new Swiper(".footer-member .swiper", {
        slidesPerView: 5,
        spaceBetween: 5,
        autoplay: {
            delay: 2500,
        },
        breakpoints: {
            0:{
                slidesPerView: 4,
                spaceBetween: 5,
            },
            320: {
                slidesPerView: 5,
                spaceBetween: 5,
            },
            1640:{
                slidesPerView: 5,
                spaceBetween: 5,
            }
        }
    });
}

export const footer = function(){
    // swiperFooterMember();
}